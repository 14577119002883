/* Enroll Now Form  */
form {
    background-color: var(--color-green);
    color: var(--color-orange);
}


.form-control,
.form-control:focus,
.form-select,
.form-select:focus {
    background-color: var(--color-grey);
    color: var(--color-orange);
}

.form-select {
    --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23ff7f5b' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
}

.form-control:focus,
.form-select:focus {
    box-shadow: 0 0 0 0.25rem rgb(255 127 91 / 25%);
    border-color: var(--color-orange);
}

.form-floating>.form-select~label,
.form-floating>.form-control:focus~label,
.form-floating>.form-control:not(:placeholder-shown)~label {
    color: rgba(var(--color-orange), .50);
}

.form-floating>.form-select~label::after,
.form-floating>.form-control:focus~label::after,
.form-floating>.form-control~label::after,
.form-floating>.form-control:not(:placeholder-shown)~label::after {
    background-color: var(--color-grey);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px var(--color-grey) inset !important;
}

input:-webkit-autofill {
    -webkit-text-fill-color: var(--color-orange) !important;
}

option:checked {
    background-color: var(--color-orange);
    color: var(--color-cream);
}